import React from 'react';

const Card = ({ className, children, title, description, link }) => (
  <div
    className={`p-12 rounded-lg border border-solid border-gray-200 ${className}`}
    style={{
      boxShadow: '0 10px 28px rgba(0,0,0,.08)'
    }}
  >
    <p className="font-semibold text-xl">{title}</p>
    <p className="mt-4">{description}</p>
    <a href={link}>Скачать</a>
    {children}
  </div>
);

export default Card;
