import React from 'react';
import Button from './Button';

const PricingCard = ({ className, children, title, description }) => (
  <div
    className={`p-12 rounded-lg border border-solid border-gray-200 ${className}`}
    style={{
      boxShadow: '0 10px 28px rgba(0,0,0,.08)'
    }}
  >
    <p className="font-semibold text-xl">{title}</p>
    <p className="mt-4">{description}</p>
    <p className="mt-4">4 490 ₽</p>
    <Button size="sm">Оплатить</Button>

    {children}
  </div>
);

export default PricingCard;
