export default [
  {
    title: 'Эвелина, хочу поблагодарить вас за спринт!',
    content:
      'Это было прекрасное путешествие в мир испанского. Вы почень позитивный, жизнерадостный и солнечный человек! Вас приятно слушать и заниматься с вами индвидиуально. Задания в каждом спринте ОГОНЬ! Каждый день что-то новое и интересно! Но, мой совет участникам: делайте задания каждый день!!! Это занимает не так много времени, потом очень сложно наверстать. тем более, что есть дни, когда можно просто посмотреть фильм или послушать радио или подкаст. Эвелина, удачи вам во всех начинаниях!',
    customerName: 'Елена',
    customerTitle: 'Участница спринта по разговорном испанскому',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Хочу вам сказать спасибо, ваш метод работает!',
    content:
      'Я, конечно, не могла быстро вспомнить все что знаю, но на самом простом уровне разговаривала и меня понимали. И самое главное, что я как-то приехала и тупо начала раговаривать, даже не успела подумать, страшно мне или нет',
    customerName: 'Светлана Яковлева',
    customerTitle: 'Участница спринта по разговорном испанскому',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Я вернулся из Мескики',
    content:
      'Эвелина, добрый день! Я вернулся из Мексики, все хорошо! Общение с местным население прошло усмегно! Тебе спасибо!',
    customerName: 'Михаил',
    customerTitle: 'Участник спринта по разговорном испанскому',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Завела испанских друзей',
    content:
      'Виктория, спасибо вам огромное, 4 месяца мы занимаемся и я учу язык раз в неделю и даже при этом условии, благодаря вашим методикам обучения, вашей вере в меня я говорю на языке и уже завела испанских друзей.',
    customerName: 'Мельникова Ирина',
    customerTitle: 'Участник спринта по разговорном испанскому',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Благодарность',
    content:
      'Благодарность - это первое слово,которое выражаю преподавателю.Виктория - это тот преподаватель,который бьется за то,чтобы ее ученики освоили базовые вещи и только потом шли дальше. Если у ученика не получается тот или иной момент,то Виктория не спускает это дело с рук по варианту и "так сойдет",она находит дополнительные упражнения,чтобы ученик мог изусить тему детально.',
    customerName: 'Илья Фомин',
    customerTitle: 'Участник спринта по разговорном испанскому',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Я вернулся из Мескики',
    content:
      'Обожаю наши уроки,я раньше думала,что учить язык трудно и довольно сложно. Однако благодаря Виктории я узнала новые методы и теперь использую их в изучении языка! Кстати поддержка и настроении Виктории очень хорошо помогают настроиться наработа и не бросить при первых трудностях',
    customerName: 'Карина',
    customerTitle: 'Участник спринта по разговорном испанскому',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];
