import React from 'react';
import Button from '../Button';
const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Spanish Sprint</h2>
        <p className="mt-5">Изучение испанского и практика коммуникативного навыка.</p>
        <h4 className="text-lg font-semibold">Рассылка</h4>
        <form>
          <input type="text" />
          <Button>¡Si!</Button>
        </form>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Ссылки</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://codebushi.com">Условия</a>
          </li>
          <li>
            <a href="https://discord.gg/J3SHq7e">Политика обработки данных</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold"> Сообщества</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://discord.gg/J3SHq7e">Дискорд</a>
          </li>
          <li>
            <a href="https://twitter.com/HuntaroSan">Телеграм</a>
          </li>
          <li>
            <a href="https://www.instagram.com/spanish_by_evelina/">Инстаграм</a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UC52Jh-FfGUY_zqb_Z2Q123g?view_as=subscriber">
              Youtube
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
